import './App.css';
import Recipes from './Recipes.js';
import React, { Component } from 'react';


export default class App extends React.Component {

	state = {
		loading: true,
		fermentables: [],
	}
	
	async componentDidMount() {
		const url = "https://www.ingbuero-david.de/cgi-bin/xml.cgi";
		//const url = "https://jsonplaceholder.typicode.com/users";
		const response = await fetch(url);
		const data = await response.json();
		this.setState({fermentables: data, loading: false});

	}

	
	render() {
		return (
			<body class="min-h-screen bg-gray-10">
				<Recipes recipes={this.state.fermentables} />
			</body>
		)
	}
}
