export default function ListItem({ recipe }) {
  return (
    <article className="p-4 flex space-x-4">
     	<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
			<div class="md:flex">
				<div class="md:flex-shrink-0">
					<img class="h-64 w-full object-cover md:w-48" src={recipe.IMAGE} alt="Pic missing..."></img>
				</div>
				<div class="p-8">
					<a href={recipe.LINK} class="block text-lg leading-tight font-medium text-black hover:underline">{recipe.NAME}</a>
					<div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{recipe.TYPE}</div>
					<div class="tracking-normal text-xs text-gray-800 font-semibold">EBC: {recipe.COLORMIN} - {recipe.COLORMAX}</div>
					<p class="mt-2 text-gray-500">{recipe.NOTES.slice(0,150)} [...]</p>
				</div>
			</div>
		</div>
	</article>
  )
}
